import { clients } from '../../util/clients';
import { getRawJwt } from '../../util/jwt';

export const types = ['', 'import', 'export'];

export const generateReportUrl = (reportTemplate, inputs, { chunkID, program }) => {
  const keyValues = (inputs || []).reduce((ac, i) => {
    const { prop } = i;
    const newKeys = Object.keys(i)
      .filter((k) => k.indexOf('value') === 0)
      .map((k) => k.replace('value', ''));
    const newKeyValues = newKeys
      .map((k) => ({
        key: `${prop}${k}`,
        value: i[`value${k}`],
      }))
      .filter((kv) => ac.indexOf(kv) === -1);
    return [...ac, ...newKeyValues];
  }, []);

  const globalVars = [
    {
      key: 'baseUrl',
      value: clients.direct.backendRest.defaults.baseURL,
    },
    {
      key: 'token',
      value: getRawJwt(),
    },
  ];
  let url = [...globalVars, ...keyValues].reduce((ac, i) => {
    const match = `$\{${i.key}}`;
    return ac.replaceAll(match, i.value);
  }, reportTemplate);
  if (chunkID) {
    const checkSpecialChar = url.match(/\?/);
    if (checkSpecialChar) {
      url = `${url}&chunkID=${chunkID}`;
    } else {
      url = `${url}?chunkID=${chunkID}`;
    }
  }

  if (program) {
    url = `${url}&program=${program}`;
  }

  return `${url}`;
};

export const sourcesByType = (type) => {
  switch (type) {
    case 'import':
      return ['', 'csv', 'csv-headerless', 'fixed-width', 'csv-ignore-header'];
    case 'export':
      return ['', 'gql', 'gql-headerless', 'backend-to-backend'];
    default:
      return [''];
  }
};

export const generateChunckID = ({ profileName, dateTime }) => {
  const profile = profileName.replace(/[^a-z0-9]/gi, '-').toLowerCase();
  const dateTimeStamp = (dateTime || new Date()).toISOString();
  return `${profile}-${dateTimeStamp}`;
};

export default {
  sourcesByType,
  generateChunckID,
};
