import { clients } from '../../util/clients';
import { parseJson, isEmptyObject } from '../../util/shared/vue-global';

const { backendRest } = clients.direct;

export const parseResponse = (response) => {
  let errorResponse;
  const resData = response.data;
  if (resData) {
    const resultData = resData.results;
    if (Array.isArray(resultData) && resultData.length > 0) {
      errorResponse = resultData[0].errors;
    }
  }
  return errorResponse;
};

const isResponseHasSingleElement = (response) => Array.isArray(response) && response.length === 1;

export const parseErrorResult = (response) => {
  const { programType } = response;
  let additionalErrors = {};

  if (!isEmptyObject(response[programType])
    && Object.prototype.hasOwnProperty.call(response[programType], 'data')) {
    additionalErrors = {
      key: programType,
      errors: response[programType].data.length > 0 ? response[programType].data : [],
      proceedImport: response[programType].proceedImport,
    };
  }
  const error = {
    generalErrors: (response.errors || []).length > 0 ? response.errors : [],
    additionalErrors,
  };
  return error;
};

export const parseError = (response) => {
  const errorResponse = parseResponse(response);
  let dialog = false;
  let errorMode;
  let errors = [];
  if (errorResponse) {
    if (isResponseHasSingleElement(errorResponse)) {
      const [jsonResponse, parseStatus] = parseJson(errorResponse);
      errors = jsonResponse;
      if (parseStatus) {
        errorMode = 'errorWithHeader';
        errors = parseErrorResult(errors);
      }
    } else {
      errors = errorResponse;
    }
    dialog = true;
  }
  return [dialog, errors, errorMode];
};

export const filePreValidate = async (params) => {
  const {
    form,
    formHeaders,
  } = params;
  const response = await backendRest.post('/transmap/import', form, {
    headers: formHeaders,
  });

  const [dialog, errors, errorMode] = parseError(response);
  return [dialog, errors, errorMode];
};

export default {
  parseResponse,
  parseError,
  filePreValidate,
};
