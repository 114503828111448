<template>
  <v-container>
    <v-row>
      <v-col cols="2" v-if="fiscalYear">
        <v-text-field
          class="tmc-import war fiscalYear"
          :value="input.valueFiscalYear"
          label="Fiscal Year"
          type="number"
          @input="emitInput('valueFiscalYear',parseFloat($event));
          loadFundData(input.valueFiscalYear);"
        ></v-text-field>
      </v-col>
      <v-col cols="3" v-if="fund">
        <v-select
          :items="fundList"
          label="Fund"
          item-text="fundDesc"
          item-value="_id"
          @change="emitFundValues($event);"
      ></v-select>
      </v-col>
      <v-col cols="3" v-if="dept && !isFundsOnly">
        <v-select
          item-text="deptDesc"
          item-value="_id"
          :items="departments"
          label="Department"
          @change="emitDepartmentValues($event);"
        ></v-select>
      </v-col>
      <v-col cols="3" v-if="account && !isFundsOnly">
        <v-select
          item-text="accDesc"
          item-value="_id"
          :items="accounts"
          label="Account"
          @change="emitAccountValues($event);"
        ></v-select>
      </v-col>
      <v-col cols="3" v-if="clearingFund && isFundsOnly && input.valueClearingFundNumber">
        <v-text-field
          classes="tmc-import readonly text-field"
          :value="input.valueClearingFundNumber"
          label="Clearing Fund"
          type="text"
          readonly
      ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {
  mapState, mapGetters, mapActions,
} from 'vuex';

const fundCrudKey = 'sharedFund';
const departmentCrudKey = 'warDepartment';
const accountCrudKey = 'warAccount';

export default {
  name: 'WarTransTypeInput',
  data: () => ({
    fundList: [],
  }),
  props: {
    input: {
      type: Object,
      default: () => ({}),
    },
    fiscalYear: {
      type: Boolean,
      default: false,
    },
    fund: {
      type: Boolean,
      default: false,
    },
    dept: {
      type: Boolean,
      default: false,
    },
    account: {
      type: Boolean,
      default: false,
    },
    clearingFund: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.intializeCriteria();
    this.loadConfig({ requiredScope: 'warconfig' }).then(() => {
      this.emitWarSettings();
    });
  },
  computed: {
    ...mapGetters([
      'todaysDate',
    ]),
    ...mapGetters('base/crud', ['items']),
    ...mapState({
      sharedFunds: (state) => state.base.crud.items.sharedFund,
      warDepartments: (state) => state.base.crud.items.warDepartment,
      warAccounts: (state) => state.base.crud.items.warAccount,
      warSettingItem: (state) => state.SystemConfig.warSettingItem,
    }),
    isFundsOnly() {
      return this.warSettingItem && this.warSettingItem.fundsOnly;
    },
    departments() {
      const departmentObjs = this.items(departmentCrudKey) || [];
      const updatedDeptObj = departmentObjs.map((d) => ({
        ...d,
        deptDesc: `${d.dept} ${d.description || ''}`,
      }));
      return [{ _id: null, dept: '', deptDesc: '' }, ...updatedDeptObj];
    },
    accounts() {
      const accountObjs = this.items(accountCrudKey) || [];
      const updatedAccObjs = accountObjs.map((a) => ({
        ...a,
        accDesc: `${a.account} ${a.shortDesc || ''}`,
      }));
      return [{ _id: null, account: '', accDesc: '' }, ...updatedAccObjs];
    },
  },
  methods: {
    ...mapActions('shared/fund', ['loadFunds']),
    ...mapActions('war/account', ['loadAccounts']),
    ...mapActions('war/department', ['loadDepartments']),
    ...mapActions('SystemConfig', ['loadConfig']),
    emitFundValues(event) {
      this.emitInput('valueFund', event);
      const matchingFund = (this.sharedFunds || []).filter((fund) => fund._id === event);
      if (matchingFund.length === 1) {
        this.emitInput('valueFundNum', matchingFund[0].fund);
      } else {
        this.emitInput('valueFundNum', 0);
      }
    },
    emitDepartmentValues(event) {
      this.emitInput('valueWarDepartment', event);
      const matchingWarDept = (this.warDepartments || []).filter((dept) => dept._id === event);
      if (matchingWarDept.length === 1) {
        this.emitInput('valueWarDepartmentNum', matchingWarDept[0].dept);
      } else {
        this.emitInput('valueWarDepartmentNum', 0);
      }
    },
    emitAccountValues(event) {
      this.emitInput('valueWarAccount', event);
      const matchingWarAccount = (this.warAccounts || []).filter((acc) => acc._id === event);
      if (matchingWarAccount.length === 1) {
        this.emitInput('valueWarAccountNum', matchingWarAccount[0].account);
      } else {
        this.emitInput('valueWarAccountNum', 0);
      }
    },
    emitWarSettings() {
      const warProgram = {};
      warProgram.isFundsOnly = this.warSettingItem && this.warSettingItem.fundsOnly;
      warProgram.multipleFunds = this.warSettingItem && this.warSettingItem.multipleFunds;
      warProgram.payrollRegisterProgram = this.warSettingItem
        && this.warSettingItem.payrollRegisterProgram;
      warProgram.glReportUseRegisterDate = this.warSettingItem
        && this.warSettingItem.glReportUseRegisterDate;
      warProgram.allowImpWithErrInWarNoSeq = this.warSettingItem
        && this.warSettingItem.allowImpWithErrInWarNoSeq;
      warProgram.clearingFund = this.warSettingItem && this.warSettingItem.clearingFund;
      this.$emit('inputArgs', warProgram);
    },
    async loadFundData(currentFY) {
      const calculatedFY = new Date(this.todaysDate).getFullYear();
      const funds = await this.loadFunds({
        criteria: { fiscalYear: currentFY || calculatedFY },
        baseCrudKey: fundCrudKey,
      });
      const fundObjs = funds || [];
      const updatedFundObj = fundObjs.map((f) => ({
        ...f,
        fundDesc: `${f.fund} ${f.description || ''}`,
      }));
      this.fundList = [{ _id: null, fund: '', fundDesc: '' }, ...updatedFundObj];
    },
    async intializeCriteria() {
      this.loadFundData(this.input.valueFiscalYear);
      this.loadDepartments({
        criteria: {
          sortBy: [
            {
              column: 'dept',
              direction: 'asc',
            },
          ],
        },
        baseCrudKey: departmentCrudKey,
      });
      this.loadAccounts({
        criteria: {
          sortBy: [
            {
              column: 'account',
              direction: 'asc',
            },
          ],
        },
        baseCrudKey: accountCrudKey,
      });
    },
    async emitInput(type, value) {
      this.$emit('input', {
        type,
        value,
      });
    },
  },
};

</script>
