<template>
  <v-container :key="reloadKey">
    <HandleImportError
      :dialog="dialog"
      :errorMode="errorMode"
      :errors="errors"
      :showDependencyBtn="showDependencyBtn"
      @createDependencies="createDependencies"
      @openCloseDialog="openCloseDialog"
      @getAdditionalImportedData="getAdditionalImportedData"
    />
    <v-row class="mt-4">
      <h3>Import Files</h3>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-select
          id="tmc-transform-import-program"
          v-model="programSelect"
          :items="programList"
          label="Select Program"
          item-text="label"
          item-value="programKey"
          @change="filterPipelineByProgram"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="(selectedProgramDetails || {}).subProgram">
      <v-col cols="8">
        <v-select
          id="tmc-transform-import-subprogram"
          v-model="subProgramSelect"
          :items="subProgramList"
          label="Select Sub Program"
          @change="onSubProgramChange"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-select
          id="tmc-transform-import-select"
          v-model="pipeline"
          :items="filteredProfiles"
          label="Type of import"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="pipelineHasInputs">
      <ImportFilesBetaInputCols
        :inputs="pipeline.inputs"
        :pipeline="pipeline"
        @inputs="pipeline.inputs = $event"
        @flagParams="pipeLineFlag"
        />
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-file-input :disabled="!pipeline"
          id="tmc-import-file-upload-beta"
          v-model="file"
          accept=".txt,.csv,.prn"
          label="Import (.txt,.csv,.prn)"
          show-size
          clearable
          @change="onFileSelected"
        >
        </v-file-input>
      </v-col>
    </v-row>
    <v-row v-if="isRequiredSftp">
      <v-col cols="8">
        <ImportFileList
          :pipeline="pipeline"
          @importDataFromFile="importDataFromFile"
        />
      </v-col>
    </v-row>
    <v-row v-if="importing">
      <v-col cols="3">
        <p>{{statusText}}</p>
      </v-col>
      <v-col cols="7">
        <v-progress-linear
          color="deep-purple accent-4"
          :value="progress"
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <v-row v-if="reload">
      <v-col>
        <v-btn
          id="tmc-btn-import-state-reload-beta"
          rounded @click="reloadMe">
          <v-icon class="mr-2">{{icons.mdiReload}}</v-icon> Load another
        </v-btn>
      </v-col>
      <v-col>
        <a v-if="hasReportTemplate"
          class="py-4"
          :href="dynamicReportUrl()"
          target="_blank">Open the report</a>
      </v-col>
      <v-col>
        <span> Imported in: {{this.timing}}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mdiReload,
} from '@mdi/js';
import {
  mapActions,
  mapMutations,
  mapGetters,
  mapState,
} from 'vuex';
import ImportFileList from '@/components/bulk/sftp/ImportFilesList.vue';
import ImportFilesBetaInputCols, { parsedPipelineItem, reducePipelineInputValues } from '../../components/bulk/ImportFilesBetaInputCols.vue';
import { clients } from '../../util/clients';
import {
  dateDiffStr,
  isHealthDeptImports,
  isWorkDayImports,
} from '../../util/shared/tmc-global';
import {
  amountToNumber,
  sanitizeFilename,
  resetWindow,
} from '../../util/shared/vue-global';
import {
  generateReportUrl,
  generateChunckID,
} from '../../components/config/TransformMapper.util';
import HandleImportError from './HandleImportError.vue';
import { filePreValidate } from './import.utils';
import { transformMapperFieldsImport, programListForImport, pipelineDefaultImport } from '../../util/shared/transform';
import { isTulsa, filterForCountySpecificProfiles } from '../../util/shared/county-specific.config';

const { backendRest } = clients.direct;

const initdata = () => ({
  form: new FormData(),
  dialog: false,
  errorMode: undefined,
  icons: {
    mdiReload,
  },
  file: undefined,
  pipeline: { ...pipelineDefaultImport },
  importing: false,
  reload: false,
  reloadKey: 0,
  statusText: '',
  progress: 0,
  timing: undefined,
  baseCrudKey: 'transform-mapper-list',
  errors: [],
  programSettings: {
    warSettings: {},
  },
  chunkID: '',
  additionalImportedData: {},
  programListItems: [...programListForImport],
  programSelect: undefined,
  subProgramSelect: undefined,
  sftpDialog: false,
});

export default {
  name: 'ImportFilesBeta',
  components: {
    ImportFilesBetaInputCols,
    HandleImportError,
    ImportFileList,
  },
  data: () => initdata(),
  computed: {
    ...mapGetters('base/crud', [
      'items',
    ]),
    ...mapState({
      warSettingItem: (state) => state.SystemConfig.warSettingItem,
      globalSettingItem: (state) => state.SystemConfig.globalSettingItem || {},
    }),
    pipelineItems() {
      return this.items(this.baseCrudKey);
    },
    subProgramList() {
      const subPrograms = (this.pipelines || []).map((p) => p.value.subProgram);
      const { countyName } = this.globalSettingItem;
      let filteredItems = [];
      if (isTulsa(countyName)) {
        filteredItems = [...new Set(subPrograms.filter((p) => p !== null))];
      } else {
        filteredItems = [...new Set(subPrograms.filter((p) => p !== null
        && !isHealthDeptImports(p) && !isWorkDayImports(p)))];
      }
      return filteredItems;
    },
    pipelines() {
      return (this.pipelineItems || [])
        .map((i) => parsedPipelineItem(i))
        .map((i) => ({
          text: i.name,
          value: i,
        }))
        .sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          }
          return 1;
        });
    },
    filteredProfiles() {
      let profiles = [...this.pipelines];
      if (this.selectedProgramDetails.subProgram) {
        const profilesArr = profiles.filter(
          (p) => p.value.subProgram === this.subProgramSelect,
        );
        profiles = profilesArr.sort((a, b) => a.text.localeCompare(b.text, 'en', { numeric: true }));
      }
      const { countyName } = this.globalSettingItem;
      const profilesAfterFilter = profiles.filter(
        (p) => filterForCountySpecificProfiles(p, countyName),
      );
      return profilesAfterFilter;
    },
    pipelineHasInputs() {
      return (this.pipeline.inputs || []).length > 0;
    },
    pipelineInputsWithValues() {
      const values = reducePipelineInputValues({ inputs: this.pipeline.inputs });
      return values;
    },
    showDependencyBtn() {
      return this.pipeline.createDependency;
    },
    selectedProgramDetails() {
      return this.programList.find((p) => p.programKey === this.programSelect) || {};
    },
    isPayrollRegister() {
      return this.warSettingItem && this.warSettingItem.payrollRegisterProgram;
    },
    programList() {
      if (!this.isPayrollRegister) {
        return this.programListItems.filter((p) => (p.programKey !== 'PAYROLL'));
      }
      return this.programListItems;
    },
    isRequiredSftp() {
      return ((this.pipeline.customConfig || {}).importProfile || {}).isRequiredSftp;
    },
  },
  created() {
    this.loadConfig({ requiredScope: 'warconfig' });
    this.loadConfig({ requiredScope: 'global' });
  },
  methods: {
    ...mapActions('SystemConfig', ['loadConfig']),
    ...mapActions('jobs', [
      'backup',
    ]),
    ...mapMutations('jobs', [
      'setPrefix',
      'setGroup',
    ]),
    ...mapActions('config', [
      'loadTransformMappers',
    ]),
    ...mapActions('Bulk', [
      'upsertODTransactions',
      'importBankVoucher',
    ]),
    ...mapActions([
      'flashError',
      'flashSuccess',
    ]),
    getAdditionalImportedData(val) {
      this.additionalImportedData = { ...val };
    },
    openCloseDialog(value) {
      this.dialog = value;
    },
    filterPipelineByProgram() {
      const criteria = {
        type: 'import',
        program: this.programSelect,
      };
      this.loadTransformMappers({
        criteria,
        fields: { ...transformMapperFieldsImport },
        baseCrudKey: this.baseCrudKey,
      });
      resetWindow(this, initdata(), { programSelect: this.programSelect });
    },
    createDependencies(value) {
      if (value) {
        if (Object.keys(this.additionalImportedData).length > 0) {
          this.form.append('additionalImportedData', JSON.stringify(this.additionalImportedData));
        }
        this.insertUploadedFile(this.form);
      }
    },
    async onFileSelected(file) {
      if (file) {
        await this.importFile(file);
      }
    },
    async insertUploadedFile(form) {
      try {
        form.append('preValidate', false);
        const self = this;
        const formHeaders = {
          'Content-Type': 'multipart/form-data',
        };
        const results = await backendRest.post('/transmap/import', form, {
          headers: formHeaders,
          onUploadProgress: (progEvt) => {
            console.info('upload');
            try {
              const percentComplete = (progEvt.loaded / progEvt.total) * 100;
              self.statusText = `${percentComplete}% of file uploaded...`;
              this.progress = percentComplete;
            } catch {
              // do nothing
            }
          },
          onDownloadProgress: (progEvt) => {
            console.info('download');
            try {
              const kbRecvd = progEvt.loaded / 1024;
              self.statusText = `Getting import progress (${amountToNumber(kbRecvd)} kb)...`;
              this.progress = kbRecvd;
            } catch {
              // do nothing
            }
          },
        });

        this.importing = false;
        this.statusText = 'Loaded!';
        this.file = undefined;

        const startDt = this.timing;
        const endDt = new Date();
        this.timing = dateDiffStr(startDt, endDt);

        this.reload = true;
        if (!results || !results.data || results.data.ok !== 1) {
          this.flashError('Unable to upload file.');
          return false;
        }
        this.flashSuccess('Imported!');

        this.form = new FormData();
        if (!(this.errors == null) && this.errors.length > 0) {
          this.$router.push({
            name: 'Auto Created Dependencies',
            params: {
              args: this.errors,
              redirectToPage: {
                url: this.dynamicReportUrl(),
                params: this.chunkID,
              },
            },
          });
        } else {
          this.openIfReportTemplate();
        }
        return true;
      } catch (err) {
        console.error('Error while upserting imported file', err);
      }
      return true;
    },
    async importFile(file) {
      try {
        this.timing = new Date();

        this.importing = true;
        this.statusText = 'Loading file...';
        let successfulBackup = false;
        if (window.confirm('Would you like to take a backup before bulk changes?')) {
          this.setGroup('tmc-local-backend');
          this.setPrefix('importfilesprebak');
          successfulBackup = await this.backup();
        }
        if (!successfulBackup) {
          this.importing = false;
          this.statusText = '';
          this.file = null;
          return false;
        }

        // import the file
        const form = new FormData();
        form.append('fileName', sanitizeFilename(file.name));
        form.append('transMapName', this.pipeline.name);
        const inputsStr = JSON.stringify(reducePipelineInputValues({
          inputs: this.pipeline.inputs,
          programSettings: this.programSettings,
        }));
        form.append('inputs', inputsStr);
        form.append('data', file);
        form.append('preValidate', true);
        this.chunkID = generateChunckID({ profileName: this.pipeline.name });
        form.append('chunkID', this.chunkID);
        form.append('profileID', this.pipeline._id);
        form.append('batchSize', this.pipeline.batchSize);

        const formHeaders = {
          'Content-Type': 'multipart/form-data',
        };
        [this.dialog, this.errors, this.errorMode] = await filePreValidate({ form, formHeaders });
        if (this.dialog) {
          this.form = form;
          return false;
        }
        this.insertUploadedFile(form);
        return true;
      } catch (err) {
        console.error(err);
        this.flashError('There was a problem while importing your file.');
        return false;
      }
    },
    dynamicReportUrl() {
      const route = generateReportUrl(
        this.pipeline.reportTemplate || '',
        this.pipeline.inputs,
        {
          chunkID: this.chunkID,
          program: this.pipeline.program,
        },
      );
      return route;
    },
    hasReportTemplate() {
      return (this.pipeline.reportTemplate || '').length > 0;
    },
    openIfReportTemplate() {
      if (this.hasReportTemplate()) {
        const toUrl = this.dynamicReportUrl();
        window.open(toUrl, '_blank');
      }
    },
    reloadMe() {
      Object.assign(this.$data, initdata());
      this.reloadKey += 1;
    },
    pipeLineFlag(value) {
      this.programSettings.warSettings = value;
    },
    onSubProgramChange() {
      this.pipeline = {};
    },
    async importDataFromFile(fileData) {
      if (fileData) {
        await this.importFile(fileData);
      }
    },
  },
};
</script>
