<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      width="auto"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          color="primary"
          v-bind="props"
          @click="dialogComputed = true"
        >
        Get Files from SFTP
        </v-btn>
      </template>
      <v-card>
        <v-card-title>Select Files</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 30rem;">
          <v-radio-group
            v-model="selectedFile"
            column
          >
            <template v-for="todo in fileList">
              <v-radio
                v-bind:key="todo"
                :label="todo"
                :value="todo"
              ></v-radio>
            </template>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="previewFile"
          >
            Preview
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <TransformMapperPreviewData
      ref="childComponentRef"
      :isImportedData="true"
      @importDataFromFile="importDataFromFile"
    />
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import TransformMapperPreviewData from '@/components/config/TransformMapperPreviewImport.vue';
import { clients } from '@/util/clients/index';

const { backendRest } = clients.direct;

export default {
  components: {
    TransformMapperPreviewData,
  },
  props: {
    pipeline: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    selectedFile: undefined,
    dialog: false,
    fileList: [],
    fileData: {},
  }),
  computed: {
    dialogComputed: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.dialog = value;
        this.fileList = [];
        this.getListFromSftp();
      },
    },
  },
  methods: {
    ...mapActions([
      'flashError',
    ]),
    async getListFromSftp() {
      let response = {};
      try {
        response = await backendRest.get('/sftp/war-county-clerk/get-import-list');
      } catch (e) {
        console.error(e);
        this.flashError('Facing issue while fetching file/s list from SFTP');
      }
      this.fileList = (response.data || []).map((r) => r.name);
    },
    async previewFile() {
      let response = {};
      if (this.selectedFile) {
        try {
          response = await backendRest.get(`/sftp/war-county-clerk/download-file?filePath=${this.selectedFile}`);
        } catch (e) {
          console.error(e);
          this.flashError('Facing issue while downloading file from SFTP');
        }
      }
      this.fileData = response.data || {};
      this.$refs.childComponentRef.previewImportedData(this.pipeline, this.fileData.parsedData || '');
    },
    importDataFromFile() {
      this.dialog = false;
      const file = new File([this.fileData.parsedData || ''], this.selectedFile);
      this.$emit('importDataFromFile', file);
    },
  },
};
</script>
