<template>
  <v-container>
    <label class="v-label v-label--active theme--light">
      {{input.label || `Official Depository`}}
    </label>
    <v-row>
      <v-col>
        <v-text-field id="odImportFyText"
          class="tmc-import od fiscalYear"
          :value="input.valueFiscalYear"
          label="Fiscal Year"
          type="number"
          @input="emitInput('valueFiscalYear',parseFloat($event));odNextTransNumbers(input);"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select id="odImportDeptSelect"
          class="tmc-import od dept"
          :items="oddepts"
          label="Department"
          item-text="caption"
          item-value="id"
          :value="input.valueDept"
          :readonly="deptReadOnly"
          @change="emitDepartmentValues($event);odNextTransNumbers(input);"
        ></v-select>
      </v-col>
      <v-col>
        <div>Transaction type: {{input.valueType}}</div>
        <div>Registration: {{odNextRegistration}}</div>
        <div v-if="input.valueType === 'VOUCHER'">Voucher: {{odNextVoucher}}</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {
  mapState,
} from 'vuex';
import { isCanadian, isCandianPayrollProfile } from '../../util/shared/county-specific.config';

const initdata = () => ({
  dept1005Id: undefined,
  deptReadOnly: false,
});

export default {
  name: 'ODFyDeptTransTypeInput',
  data: () => initdata(),
  props: {
    input: {
      type: Object,
      default: () => ({}),
    },
    pipeline: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    this.loadODDepts();
    this.countySpecificProfile();
  },
  computed: {
    ...mapState({
      oddepts: (state) => state.OD.departments,
      odNextVoucher: (state) => state.OD.nextVoucherNumber,
      odNextRegistration: (state) => state.OD.nextRegistrationNumber,
      globalSettingItem: (state) => state.SystemConfig.globalSettingItem,
    }),
  },
  watch: {
    pipeline() {
      this.countySpecificProfile();
    },
  },
  methods: {
    async loadODDepts() {
      await this.$store.dispatch('OD/loadDepartments');
      /*
        Todo - Make more managable logic for county specific implemenation
        Specific Logic for canadian county
      */
      const { countyName } = this.globalSettingItem;
      const { name } = this.pipeline;
      if (isCanadian(countyName) && isCandianPayrollProfile(name)) {
        this.dept1005Id = ((this.oddepts || []).find((d) => d.dept === 1005) || {}).id;
        this.deptReadOnly = true;
        this.emitDepartmentValues(this.dept1005Id);
      } else {
        this.deptReadOnly = false;
        this.emitDepartmentValues(undefined);
      }
      this.odNextTransNumbers(this.input);
      // this.countySpecificProfile();
    },
    emitDepartmentValues(event) {
      this.emitInput('valueDept', event);
      const matchingDepts = (this.oddepts || []).filter((d) => d.id === event);
      if (matchingDepts.length === 1) {
        this.emitInput('valueDeptNum', matchingDepts[0].dept);
        this.emitInput('valueDeptDesc', matchingDepts[0].description);
      } else {
        this.emitInput('valueDeptNum', 0);
        this.emitInput('valueDeptDesc', '');
      }
    },
    async emitInput(type, value) {
      this.$emit('input', {
        type,
        value,
      });
    },
    async odNextTransNumbers(input) {
      const { valueDept, valueType, valueFiscalYear } = input;
      if (valueDept && valueType && valueFiscalYear) {
        await this.$store.dispatch(
          'OD/loadTransStats',
          {
            type: valueType,
            department: valueDept,
            fiscalYear: valueFiscalYear,
          },
        );
        return {
          ...input,
          nextVoucher: this.odNextVoucher ? this.odNextVoucher - 1 : 0,
          nextRegistration: this.odNextRegistration ? this.odNextRegistration - 1 : 0,
        };
      }
      return {
        ...input,
        nextVoucher: 0,
        nextRegistration: 0,
      };
    },
    countySpecificProfile() {
      /*
        Todo - Make more managable logic for county specific implemenation
        Specific Logic for canadian county
      */
      const isCountyMatched = ((this.pipeline || {}).countySpecific || [])
        .includes(((this.globalSettingItem || {}).countyName || '').toLowerCase());
      if (isCountyMatched) {
        this.deptReadOnly = true;
        this.emitDepartmentValues(this.dept1005Id);
      } else {
        this.deptReadOnly = false;
        this.emitDepartmentValues(undefined);
      }
      this.odNextTransNumbers(this.input);
    },
  },
};

</script>
