<template>
  <v-card>
    <v-card-title>
      {{ pageTitle }}
    </v-card-title>
    <v-card-text>
    <v-simple-table dense>
      <tbody>
        <tr v-for="(item, index) in taxLevyErrors" :key="index">
          <td> Tax Fund: {{ item.taxFund }}</td>
          <td>
            <v-select
              :items="fundList"
              label="Fund"
              item-text="fundDesc"
              item-value="_id"
              @change=" val => slectedSharedFundItem(item, val)"
              :rules="required"
            ></v-select>
          </td>
        </tr>
      </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { toFiscalYear } from '../../util/shared/tmc-global';

export default {
  name: 'HandleTaxLevyError',
  props: {
    taxLevyErrors: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    fundList: [],
    selectedSharedFundsArr: [],
  }),
  created() {
    this.loadFundData();
    this.intializeProceedBtn();
  },
  computed: {
    ...mapGetters(['todaysDate']),
    required() {
      return [
        (value) => !!value || 'Required',
      ];
    },
    taxLevyErr() {
      const value = this.taxLevyErrors.length > 0 ? this.taxLevyErrors : [];
      return value;
    },
    pageTitle() {
      return 'Errors in TaxLevy List';
    },
  },
  methods: {
    ...mapActions('shared/fund', ['loadFunds']),
    intializeProceedBtn() {
      this.$emit('proceedImportFile', false);
    },
    async loadFundData() {
      const currentFiscalYear = toFiscalYear(new Date(this.todaysDate));
      const funds = await this.loadFunds({
        criteria: { fiscalYear: currentFiscalYear },
      });
      const fundObjs = funds || [];
      const updatedFundObj = fundObjs.map((f) => ({
        ...f,
        fundDesc: `${f.fund} ${f.description || ''}`,
      }));
      this.fundList = [{ _id: null, fund: '', fundDesc: '' }, ...updatedFundObj];
    },
    slectedSharedFundItem(item, val) {
      const itemToUpdate = this.taxLevyErr.find((data) => data.taxFund === item.taxFund);
      itemToUpdate.fund = val;
      const isSharedFundAssigned = this.taxLevyErr.every((
        taxLevyObj,
      ) => Object.prototype.hasOwnProperty.call(taxLevyObj, 'fund'));
      if (isSharedFundAssigned) {
        const updatedImportTaxLevyArr = this.taxLevyErr;
        this.$emit('updatedImportTaxLevy', updatedImportTaxLevyArr);
      }
    },
  },
};
</script>
