<template>
  <v-card>
    <v-card-title>
      {{ pageTitle }}
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="setHeadersBySetting"
        :items="warrantErr"
        class="elevation-1"
        :hide-default-footer="true"
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'HandleWarSequenceError',
  props: {
    warErrors: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    headers: [
      { text: 'FY', value: 'fiscalYear' },
      { text: 'Fund', value: 'fund' },
      { text: 'Warrant', value: 'warrant' },
      { text: 'Expected ', value: 'expectedWarNo' },
      { text: 'Fund Balance ', value: 'balance' },
    ],
  }),
  created() {
    this.loadConfig({ requiredScope: 'warconfig' });
  },
  computed: {
    ...mapState({
      warSettingItem: (state) => state.SystemConfig.warSettingItem,
    }),
    warrantErr() {
      const value = this.warErrors.length > 0 ? this.warErrors : [];
      return value;
    },
    isErrorWithHeader() {
      return this.errorMode === 'errorWithHeader';
    },
    isInsufficentFundsWarning() {
      return this.warSettingItem && this.warSettingItem.insufficentFundsWarning;
    },
    pageTitle() {
      if (this.isInsufficentFundsWarning) {
        return 'Errors in Warrant Number Sequence & Fund Balance Warning';
      }
      return 'Errors in Warrant Number Sequence';
    },
    setHeadersBySetting() {
      let filteredHeaders;
      if (this.warSettingItem && !this.isInsufficentFundsWarning) {
        filteredHeaders = this.headers.filter((h) => h.value !== 'balance');
      } else {
        filteredHeaders = this.headers;
      }
      return filteredHeaders;
    },
  },
  methods: {
    ...mapActions('SystemConfig', ['loadConfig']),
    proceedImportFile() {
      this.$emit('proceedImportFile', true);
    },
  },
};
</script>
