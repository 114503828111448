<template>
  <v-container>
    <h4>Inputs for this type of import:</h4>
    <v-col class="elevation-1"
      v-for="input in inputsInternal" :key="input.prop"
    >
      <v-text-field v-if="input.type === 'fiscalYear'"
        class="tmc-import fiscalYear"
        v-model.number="input.value"
        :label="input.label || 'Fiscal Year'"
        type="number"
        @input="emitInputs"
      ></v-text-field>
      <ODFyDeptTransTypeInput v-else-if="input.type === 'fiscalYearDeptOD'"
        ref="fiscalYearDeptOD"
        :input="input"
        :pipeline="pipeline"
        @input="input[$event.type] = $event.value"
      />
      <WarTransTypeInput v-else-if="input.type === 'warDetails'"
        ref="warDetails"
        :input="input"
        :fiscalYear="true"
        :fund="true"
        :dept="true"
        :account="true"
        :clearingFund="isClearingFund"
        @input="input[$event.type] = $event.value"
        @inputArgs="handleArgs"
      />
      <WarTransTypeInput v-else-if="input.type === 'warDepartmentsOnly'"
        ref="warDepartmentsOnly"
        :input="input"
        :dept="true"
        @input="input[$event.type] = $event.value"
        @inputArgs="handleArgs"
      />
      <WarTransTypeInput v-else-if="input.type === 'warFundOnlyOptional'"
        ref="warFundOnlyOptional"
        :input="input"
        :fiscalYear="true"
        :fund="true"
        :clearingFund="isClearingFund"
        @input="input[$event.type] = $event.value"
        @inputArgs="handleArgs"
      />
      <ClarionDateControl v-else-if="input.type === 'date'"
        v-model="input.value"
        :isRequired="true"
        :isIsoDate="true"
        :label="input.label || 'Date'"
        classes="tmc-import date"
        @input="emitInputs"
        @blur="emitInputs"
      />
      <ClarionDateControl v-else-if="input.type === 'optionalDate'"
        v-model="input.value"
        :isIsoDate="true"
        :label="'Register Date (Optional)'"
        classes="tmc-import date"
        @input="emitInputs"
        @blur="emitInputs"
      />
      <ClarionDateControl v-else-if="input.type === 'eftDate'"
        v-model="input.value"
        :isIsoDate="true"
        :label="'EFT Date (Optional)'"
        @input="emitInputs"
        @blur="emitInputs"
      />
      <v-text-field v-else-if="input.type === 'constant'"
        classes="tmc-import readonly text-field"
        v-model="input.value"
        :label="input.label || input.prop"
        type="text"
        readonly
      ></v-text-field>
      <span v-else>
        Unknown type: "{{input.type}}"
      </span>
    </v-col>
  </v-container>
</template>

<script>

import {
  mapState,
} from 'vuex';

import ClarionDateControl from '../common/ClarionDateControl.vue';
import ODFyDeptTransTypeInput from '../../views/bulk/ODFyDeptTransTypeInput.vue';
import WarTransTypeInput from '../../views/bulk/WarTransTypeInput.vue';
import { isEmptyObject } from '../../util/shared/vue-global';

import {
  toFiscalYear,
  toIsoDate,
} from '../../util/shared/tmc-global';

export const parsedPipelineItem = (i) => {
  const result = {
    ...i,
    inputs: JSON.parse(i.inputs || '[]'),
    pipeline: JSON.parse(i.pipeline || '[]'),
    fixedWidthTemplate: JSON.parse(i.fixedWidthTemplate || '[]'),
  };
  return result;
};

export const reducePipelineInputValues = ({ inputs, programSettings }) => {
  const newItems = inputs.reduce((ac, i) => {
    const {
      type,
      prop,
      value,
      valueFiscalYear,
      valueDept,
      valueDeptNum,
      valueDeptDesc,
      valueType,
      valueFundNum,
      valueClearingFundNumber,
      valueWarDepartmentNum,
      valueWarAccountNum,
    } = i;
    const newI = [];
    switch (type) {
      case 'date':
      case 'fiscalYear': {
        newI.push({
          type: 'constant',
          prop,
          value,
        });
        break;
      }
      case 'eftDate': {
        newI.push({
          type: 'optionalConstant',
          prop: 'eftDate',
          value,
        });
        break;
      }
      case 'optionalDate': {
        newI.push({
          type: 'optionalConstant',
          prop: 'inputRegDate',
          value,
        });
        break;
      }
      case 'fiscalYearDeptOD': {
        newI.push({
          type: 'constant',
          prop: `${prop}Dept`,
          value: valueDept,
        });
        newI.push({
          type: 'constant',
          prop: `${prop}DeptNum`,
          value: valueDeptNum,
        });
        newI.push({
          type: 'constant',
          prop: `${prop}DeptDesc`,
          value: valueDeptDesc,
        });
        newI.push({
          type: 'constant',
          prop: `${prop}Fy`,
          value: valueFiscalYear,
        });
        newI.push({
          type: 'constant',
          prop: `${prop}Type`,
          value: valueType,
        });
        break;
      }
      case 'warDetails': {
        newI.push({
          type: 'constant',
          prop: 'fiscalYear',
          value: valueFiscalYear,
        });
        newI.push({
          type: 'constant',
          prop: 'fund',
          value: valueFundNum,
        });
        if (Object.prototype.hasOwnProperty.call(programSettings, 'warSettings')) {
          if (programSettings.warSettings
          && (programSettings.warSettings.isFundsOnly !== true)) {
            newI.push({
              type: 'constant',
              prop: 'department',
              value: valueWarDepartmentNum,
            });
            newI.push({
              type: 'constant',
              prop: 'account',
              value: valueWarAccountNum,
            });
          }
          if (programSettings.warSettings
          && (programSettings.warSettings.clearingFund === true)) {
            newI.push({
              type: 'optionalConstant',
              prop: 'inputClearingFundNumber',
              value: valueClearingFundNumber,
            });
          }
        }
        break;
      }
      case 'warDepartmentsOnly': {
        if (Object.prototype.hasOwnProperty.call(programSettings, 'warSettings')) {
          if (programSettings.warSettings
          && (programSettings.warSettings.isFundsOnly !== true)) {
            newI.push({
              type: 'optionalConstant',
              prop: 'department',
              value: valueWarDepartmentNum,
            });
          }
        }
        break;
      }
      case 'warFundOnlyOptional': {
        newI.push({
          type: 'constant',
          prop: 'fiscalYear',
          value: valueFiscalYear,
        });
        newI.push({
          type: 'optionalConstant',
          prop: 'inputFund',
          value: valueFundNum,
        });
        if (Object.prototype.hasOwnProperty.call(programSettings, 'warSettings')) {
          if (programSettings.warSettings
          && (programSettings.warSettings.clearingFund === true)) {
            newI.push({
              type: 'optionalConstant',
              prop: 'inputClearingFundNumber',
              value: valueClearingFundNumber,
            });
          }
        }
        break;
      }
      default: {
        newI.push(i);
        break;
      }
    }
    return [...ac, ...newI];
  }, []);
  return newItems;
};

const initdata = () => ({
  inputsInternal: [],
});

export default {
  name: 'ImportFilesBetaInputCols',
  components: {
    ClarionDateControl,
    ODFyDeptTransTypeInput,
    WarTransTypeInput,
  },
  data: () => initdata(),
  props: {
    inputs: {
      type: Array,
      default: () => ([]),
    },
    pipeline: {
      type: Object,
      default: () => ({}),
    },
  },
  async created() {
    await this.initializeInputs(this.inputs);
    this.emitInputs();
  },
  computed: {
    ...mapState({
      oddepts: (state) => state.OD.departments,
      sharedFunds: (state) => state.base.crud.items.sharedFund,
      warDepartments: (state) => state.base.crud.items.warDepartment,
      warAccounts: (state) => state.base.crud.items.warAccount,
      warSettingItem: (state) => state.SystemConfig.warSettingItem,
    }),
    isClearingFund() {
      return this.warSettingItem && this.warSettingItem.clearingFund;
    },
  },
  methods: {
    async emitInputs() {
      this.$emit('inputs', this.inputsInternal);
    },
    async initializeInputs(inputs) {
      this.inputsInternal = await Promise.all(inputs
        .map(async (i) => {
          switch (i.type) {
            case 'fiscalYear':
              return {
                ...i,
                value: toFiscalYear(new Date()),
              };
            case 'date':
              return {
                ...i,
                value: toIsoDate(),
              };
            case 'eftDate':
              return {
                ...i,
                value: null,
              };
            case 'fiscalYearDeptOD': {
              const { valueDept } = i;
              let valueDeptNum = 0;
              let valueDeptDesc = '';
              if (valueDept) {
                const matchingDepts = (this.oddepts || []).filter((d) => d.id === valueDept);
                if (matchingDepts.length === 1) {
                  valueDeptNum = matchingDepts[0].dept;
                  valueDeptDesc = matchingDepts[0].description;
                }
              }
              return {
                ...i,
                valueFiscalYear: i.valueFiscalYear || toFiscalYear(new Date()),
                valueDept,
                valueDeptNum,
                valueDeptDesc,
              };
            }
            case 'warDetails': {
              const {
                valueFund, valueWarDeptartment, valueWarAccount,
                valueClearingFundNumber,
              } = i;
              let valueFundNum = 0;
              let valueWarDepartmentNum = 0;
              let valueWarAccountNum = 0;
              if (valueFund) {
                const matchingFund = (this.sharedFunds || []).find((f) => f._id === valueFund);
                if (!isEmptyObject(matchingFund)) {
                  valueFundNum = matchingFund.fund;
                }
              }
              if (valueWarDeptartment) {
                const matchingWarDept = (this.warDepartments || [])
                  .find((d) => d._id === valueWarDeptartment);
                if (!isEmptyObject(matchingWarDept)) {
                  valueWarDepartmentNum = matchingWarDept.dept;
                }
              }
              if (valueWarAccount) {
                const matchingWarAccount = (this.warAccounts || [])
                  .find((a) => a._id === valueWarAccount);
                if (!isEmptyObject(matchingWarAccount)) {
                  valueWarAccountNum = matchingWarAccount.account;
                }
              }
              return {
                ...i,
                valueFiscalYear: i.valueFiscalYear || toFiscalYear(new Date()),
                valueFund,
                valueFundNum,
                valueWarDeptartment,
                valueWarDepartmentNum,
                valueWarAccount,
                valueWarAccountNum,
                valueClearingFundNumber,
              };
            }
            case 'warDepartmentsOnly': {
              const { valueWarDeptartment } = i;
              let valueWarDepartmentNum = 0;
              if (valueWarDeptartment) {
                const matchingWarDept = (this.warDepartments || [])
                  .find((d) => d._id === valueWarDeptartment);
                if (!isEmptyObject(matchingWarDept)) {
                  valueWarDepartmentNum = matchingWarDept.dept;
                }
              }
              return {
                ...i,
                valueFiscalYear: i.valueFiscalYear || toFiscalYear(new Date()),
                valueWarDeptartment,
                valueWarDepartmentNum,
              };
            }
            case 'warFundOnlyOptional': {
              const { valueFund } = i;
              let valueFundNum = 0;
              if (valueFund) {
                const matchingFund = (this.sharedFunds || []).find((f) => f._id === valueFund);
                if (!isEmptyObject(matchingFund)) {
                  valueFundNum = matchingFund.fund;
                }
              }
              return {
                ...i,
                valueFiscalYear: i.valueFiscalYear || toFiscalYear(new Date()),
                valueFund,
                valueFundNum,
              };
            }
            default:
              return i;
          }
        }));
    },
    handleArgs(value) {
      this.$emit('flagParams', value);
    },
  },
};

</script>
