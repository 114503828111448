<template>
  <v-dialog v-model="dialog">
    <template v-if="!isImportedData" v-slot:activator="{ on, attrs }">
      <v-btn id="tmc-config-preview-dialog-btn"
        @click="onActivate" v-bind="attrs" v-on="on">Preview Output</v-btn>
    </template>
    <v-card :key="reloadKey">
        <v-card-title>
          <span class="headline">Preview Import Data</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <ImportFilesBetaInputCols
                :inputs="editedItem.inputs"
                @inputs="editedItem.inputs = $event" />
            </v-row>
            <v-row v-if="!isImportedData">
              <v-btn id="tmc-config-preview-validate" @click="onPreview">Preview / Validate</v-btn>
            </v-row>
            <v-row>
              <v-col cols="2">
                <span>Preview Results:</span>
              </v-col>
              <v-col v-if="showPreview" cols="3">
                <v-icon small>{{okIcon}}</v-icon>
                <span id="tmc-config-preview-validation-msg"
                  class="py-2"
                >{{okIcon === icons.mdiThumbUp ? 'validates!' : 'validation problem'}}</span>
              </v-col>
              <v-col v-if="hasReportTemplate">
                <a
                  :href="dynamicReportUrl"
                  target="_blank">Preview the report URL</a>
              </v-col>
            </v-row>
            <v-row v-if="showPreview && response.results">
              <v-col cols="1" />
              <v-col cols="10">
                <div v-if="hasErrors" class="blue lighten-5 rounded-lg pa-6">
                  <v-row class="red--text" v-for="error in errorsReduced" :key="error.id">
                    {{error.error}}
                  </v-row>
                </div>
                <v-data-table v-if="hasGqlObjs"
                  id="tmc-config-preview-data-table"
                  :items="gqlObjsReduced"
                  :headers="gqlObjHeaders"
                  caption="Upserted data preview"
                  dense
                  class="elevation-1"
                >
                  <template v-slot:[`item`]="{ item, headers }">
                    <CRUDRow
                      :canEdit="false"
                      :canDelete="false"
                      :item="item"
                      :headers="headers"
                    />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            v-if="isImportedData"
            color="blue-darken-1"
            variant="text"
            @click="importDataFromFile"
          >
            Import File Data
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import {
  mapGetters, mapActions, mapState,
} from 'vuex';

import {
  mdiThumbUp,
  mdiThumbDown,
} from '@mdi/js';

import { clients } from '../../util/clients';
import CRUDRow from '../common/base/crud/CRUDRow.vue';
import { generateReportUrl } from './TransformMapper.util';
import ImportFilesBetaInputCols, { parsedPipelineItem, reducePipelineInputValues } from '../bulk/ImportFilesBetaInputCols.vue';

let errorId = 0;
let reloadId = 0;

const { backendRest } = clients.direct;
const initdata = () => ({
  icons: {
    mdiThumbUp,
    mdiThumbDown,
  },
  editedItem: {},
  dialog: false,
  showPreview: false,
  response: {
    results: [],
    ok: 1,
  },
  reloadKey: undefined,
});
export default {
  name: 'TransformMapperPreviewImport',
  components: {
    ImportFilesBetaInputCols,
    CRUDRow,
  },
  props: {
    baseCrudKey: {
      type: String,
    },
    isImportedData: {
      type: Boolean,
      default: false,
    },
  },
  data: () => initdata(),
  created() {
    // throw-away / preview copy of the mapping
    // this.editedItem = parsedPipelineItem(this.item(this.baseCrudKey));
    this.loadConfig({ requiredScope: 'warconfig' });
  },
  computed: {
    ...mapGetters('base/crud', [
      'item',
    ]),
    ...mapState({
      warSettingItem: (state) => state.SystemConfig.warSettingItem,
    }),
    isFundsOnly() {
      return this.warSettingItem && this.warSettingItem.fundsOnly;
    },
    okIcon() {
      if (this.response && this.response.ok === 1) {
        return this.icons.mdiThumbUp;
      }
      return this.icons.mdiThumbDown;
    },
    hasErrors() {
      return this.errorsReduced.length > 0;
    },
    errorsReduced() {
      const results = this.response.results || [];
      const errors = [];
      results
        .filter((r) => r.errors && Array.isArray(r.errors))
        .map((r) => {
          const uniqueErrors = r.errors
            .map((e) => {
              const result = { id: errorId += 1, error: e };
              return result;
            });
          errors.push(...uniqueErrors);
          return r;
        });
      return errors;
    },
    hasGqlObjs() {
      return this.gqlObjsReduced.length > 0;
    },
    gqlObjsReduced() {
      const results = this.response.results || [];
      const objs = [];
      results
        .filter((r) => r.upserts != null)
        .map((r) => {
          const uniqueErrors = r.upserts;
          objs.push(...uniqueErrors);
          return r;
        });
      return objs;
    },
    gqlObjHeaders() {
      const headers = this.gqlObjsReduced
        .reduce((ac, i) => {
          const newKeys = Object.keys(i)
            .filter((k) => ac.indexOf(k) === -1);
          return [...ac, ...newKeys];
        }, [])
        .map((h) => ({
          text: h,
          value: h,
        }));
      return headers;
    },
    hasReportTemplate() {
      return (this.editedItem.reportTemplate || '').length > 0;
    },
    dynamicReportUrl() {
      return generateReportUrl(this.editedItem.reportTemplate, this.editedItem.inputs, {});
    },
  },
  methods: {
    ...mapActions('SystemConfig', ['loadConfig']),
    onActivate(selectedPipeline, DataFromimportFile) {
      Object.assign(this.$data, initdata());
      reloadId += 1;
      this.reloadKey = reloadId;
      // throw-away / preview copy of the mapping
      if (this.isImportedData) {
        this.editedItem = { ...selectedPipeline };
        this.editedItem.previewData = DataFromimportFile;
      } else {
        this.editedItem = parsedPipelineItem(this.item(this.baseCrudKey));
      }
    },
    previewImportedData(selectedPipeline, importFileData) {
      this.onActivate(selectedPipeline, importFileData);
      this.dialog = true;
      this.onPreview();
    },
    async onPreview() {
      const file = new Blob([this.editedItem.previewData], {
        type: 'text/plain',
      });
      const programSettings = {
        warSettings: this.isFundsOnly,
      };
      const form = new FormData();
      form.append('fileName', 'previewdata.txt');
      form.append('transMapName', this.editedItem.name);
      const inputsStr = JSON.stringify(
        reducePipelineInputValues({ inputs: this.editedItem.inputs, programSettings }),
      );
      form.append('inputs', inputsStr);
      form.append('data', file);
      form.append('preValidate', true);
      form.append('isPreviewDataFlag', true);
      form.append('profileID', this.editedItem._id);

      const formHeaders = {
        'Content-Type': 'multipart/form-data',
      };
      const response = await backendRest.post('/transmap/import', form, {
        headers: formHeaders,
      });
      this.showPreview = true;
      this.response = response.data;
    },
    importDataFromFile() {
      this.$emit('importDataFromFile', this.editedItem.previewData);
      this.dialog = false;
    },
  },
};
</script>
