<template>
  <v-dialog
    v-model="openCloseDialog"
    persistent
    max-width="60vw"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Errors while importing data</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="importForm">
          <div v-if="isErrorWithHeader">
            <v-data-table
              :headers="headers"
              :items="generalErrors"
              class="elevation-1"
              :hide-default-footer="true"
            ></v-data-table>
            <div v-if="isVoucherSpecificError">
              <HandleVoucherSequenceError
                :voucherSequenceErrors="additionalErrors" />
            </div>
            <div v-if="isWarrantSpecificError">
              <HandleWarSequenceError
                :warErrors="additionalErrors" />
            </div>
            <div v-if="isTaxLevySpecificError">
              <HandleTaxLevyError
                :taxLevyErrors="additionalErrors"
                @updatedImportTaxLevy="fetchAndEmitImportedTaxLevy"
                />
            </div>
          </div>
          <div v-else class="blue lighten-5 rounded-lg pa-6">
            {{ errors }}
          </div>
        </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
         <v-btn
          text color="blue darken-1"
          :disabled="!showProceedBtn"
          @click="createDependency"
          >
            Proceed by creating
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="openCloseDialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { isEmptyObject } from '../../util/shared/vue-global';
import HandleWarSequenceError from './HandleWarSequenceError.vue';
import HandleVoucherSequenceError from './HandleVoucherSequenceError.vue';
import HandleTaxLevyError from './HandleTaxLevyError.vue';

const initdata = () => ({
  headers: [
    { text: 'Entity', value: 'errorKey' },
    { text: 'Error Data', value: 'data' },
    { text: 'Error message', value: 'message' },
  ],
});

export default {
  name: 'HandleImportError',
  components: {
    HandleVoucherSequenceError,
    HandleWarSequenceError,
    HandleTaxLevyError,
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    errorMode: {
      type: String,
      default: () => '',
    },
    errors: {
      type: [Array, Object],
      default: () => [],
    },
    createDependencies: {
      type: Boolean,
      default: () => false,
    },
    showDependencyBtn: {
      type: Boolean,
      default: () => true,
    },
  },
  data: () => initdata(),
  computed: {
    ...mapState({
      warSettingItem: (state) => state.SystemConfig.warSettingItem,
    }),
    openCloseDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('openCloseDialog', value);
      },
    },
    generalErrors() {
      return this.errors.generalErrors || [];
    },
    isVoucherSpecificError() {
      return this.errors.additionalErrors && this.errors.additionalErrors.key === 'voucher';
    },
    isWarrantSpecificError() {
      return this.errors.additionalErrors && this.errors.additionalErrors.key === 'warrants';
    },
    isTaxLevySpecificError() {
      return this.errors.additionalErrors && this.errors.additionalErrors.key === 'taxLevy';
    },
    additionalErrors() {
      return (this.errors.additionalErrors || {}).errors || [];
    },
    isErrorWithHeader() {
      return this.errorMode === 'errorWithHeader';
    },
    allowImpWithErrInWarNoSeq() {
      return this.warSettingItem && this.warSettingItem.allowImpWithErrInWarNoSeq;
    },
    showProceedBtn() {
      if (this.isErrorWithHeader) {
        if (this.showDependencyBtn && !isEmptyObject(this.errors.additionalErrors)) {
          return this.showDependencyBtn && this.allowImpWithErrInWarNoSeq;
        }
        return true;
      }
      return false;
    },
  },
  methods: {
    createDependency() {
      if (this.$refs.importForm.validate()) {
        this.$emit('createDependencies', true);
        this.$emit('openCloseDialog', false);
      }
    },
    fetchAndEmitImportedTaxLevy(value) {
      let additionalImportedData = {};
      if (Array.isArray(value) && value.length > 0) {
        additionalImportedData = {
          taxLevyData: value,
        };
        this.$emit('getAdditionalImportedData', additionalImportedData);
      }
    },
  },
};
</script>
